import React from "react"
import styled from "styled-components"

const RightSection = ({ title, description, img, addon }) => (
  <Container>
    <Group>
      <TextGroup>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Addon>{addon}</Addon>
      </TextGroup>
      <Image img={img} />
    </Group>
  </Container>
)

export default RightSection

const Container = styled.div`
  width: 100%;
  display: flex;
`
const Group = styled.div`
  padding: 60px;
  display: flex;
  justify-content: space-between;
  max-width: 1188px;
  margin: auto;

  @media (max-width: 776px) {
    flex-direction: column;
    padding: 30px;
  }
`

const TextGroup = styled.div`
  width: 50%;
  padding-right: 60px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 776px) {
    width: 100%;
    padding-right: 0px;
  }
`

const Title = styled.h2`
  margin: 0px 0px 10px 0px;
`
const Description = styled.p`
  color: #576474;
  margin-bottom: 0;
  @media (max-width: 776px) {
    margin-bottom: 20px;
  }
`

const Image = styled.div`
  width: 50%;
  height: 370px;
  background: url(${props => props.img});
  background-size: cover;
  max-width: 550px;

  @media (max-width: 776px) {
    width: 100%;
    max-width: 100%;
    padding-right: 0px;
  }
`

const Addon = styled.div``
