import React from "react"
import styled from "styled-components"
import {
  mainHorizontalSection1,
  mainHorizontalSection2,
} from "../../providers/staticPartnersContent"

const HorizontalSection = props => {
  const data =
    props.type === 1 ? mainHorizontalSection1 : mainHorizontalSection2
  const type = props.type === 1 ? "Main" : "Second"
  return (
    <Container type={type}>
      <Title type={type}>{props.title}</Title>
      <ItemWrapper>
        {data.map((elem, index) => {
          return (
            <Item key={index}>
              <CoverImage type={type}>
                <Icon icon={elem.icon} />
              </CoverImage>
              <Claim type={type}>{elem.title}</Claim>
              <Text type={type}>{elem.description}</Text>
            </Item>
          )
        })}
      </ItemWrapper>
    </Container>
  )
}

export default HorizontalSection

const Container = styled.div`
  background-color: ${props => (props.type == "Main" ? "#222232" : "#F7F8FA")};
  width: 100%;
`
const Title = styled.h2`
  color: ${props => (props.type == "Main" ? "white" : "#1C2632")};
  text-align: center;
  padding-top: 60px;
  margin: auto;
`
const ItemWrapper = styled.div`
  display: flex;
  max-width: 1188px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 60px;

  margin: auto;
  margin-top: 30px;
  padding-bottom: 60px;

  @media (max-width: 776px) {
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding-left: 0px;
  }
`
const Item = styled.div`
  justify-content: center;
  max-width: 300px;
  padding-right: 60px;

  @media (max-width: 776px) {
    max-width: 100%;
    margin-right: 60px;
    margin-left: 60px;
    padding-right: 0px;
  }
`
const CoverImage = styled.div`
  display: flex;
  height: 148px;
  width: 148px;
  background-color: ${props => (props.type == "Main" ? "#29293C" : "#FFFFFF")};
  border-radius: 90px;
  margin: auto;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => (props.type == "Main" ? "#393956" : "#FFFFFF")};
  box-shadow: ${props =>
    props.type == "Main" ? null : "0 20px 40px rgba(0, 0, 0, 0.10)"};
`
const Icon = styled.div`
  height: 70%;
  width: 70%;
  background: url(${props => props.icon});
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
`

const Claim = styled.h3`
  color: ${props => (props.type == "Main" ? "white" : "#1C2632")};
`
const Text = styled.p`
  color: ${props => (props.type == "Main" ? "#bdbdc1" : "#898F96")};
`
