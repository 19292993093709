import React from "react"
import styled from "styled-components"

const MainButton = ({ title }) => (
  <Container>
    <ButtonText>{title}</ButtonText>
  </Container>
)

export default MainButton

const Container = styled.div`
  display: flex;
  max-width: 250px;
  background-color: #00cc9a;
  border-radius: 40px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  transition: 1s;

  &:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
    transform: translateY(-3px);
  }
`
const ButtonText = styled.p`
  font-weight: 600;
  color: white;
  margin: auto;
  font-size: 14px;
  padding: 16px 26px 16px 26px;
`
