import React from "react"
import styled from "styled-components"
import MainButton from "../MainButton"

const HorizontalMessage = props => (
  <Container>
    <Group>
      <Title>👋 Estamos contigo</Title>
      <Description>
        Pregúntanos cualquier duda o contacta con nosotros para que podamos encontrar cual es el mejor camino a seguir para hacer crecer tu negocio.
      </Description>
      <ButtonWrapper>
        <a
          href="mailto:hola@choosify.es?subject=Estoy%20interesado%20en%20obtener%20más%20información"
          class="open-intercom"
          target="_blank"
          rel="nofollow"
        >
          <MainButton title={"ESTOY INTERESADO"} />
        </a>
      </ButtonWrapper>
    </Group>
  </Container>
)

export default HorizontalMessage

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const Group = styled.div`
  display: flex;
  padding: 60px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 700px;
`

const Title = styled.h2`
  margin: 0px 0px 0px 0px;
`
const Description = styled.p``

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`
