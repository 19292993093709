import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Partners/Hero"
import HorizontalSection from "../components/Partners/HorizontalSection"
import RightSection from "../components/Partners/RightSection"
import LeftSection from "../components/Partners/LeftSection"
import HorizontalMessage from "../components/Partners/HorizontalMessage"
import { sectionContent } from "../providers/staticPartnersContent"

const Restaurantes = () => (
  <Layout>
    <SEO
      title="Choosify para Negocios: Todo un mar de posibilidades"
      description="Con Choosify podrás hacer crecer tu negocio gracias a nuestras diferentes estrategias enfocadas en la adquisición y fidelización de clientes. "
      path="https://choosify.es/negocios"
    />
    <Hero />
    <HorizontalSection type={1} title={"¿Qué aportamos a tu negocio?"} />
    {sectionContent.map((elem, index) => {
      if (index % 2 === 0) {
        return (
          <RightSection
            title={elem.title}
            description={elem.description}
            img={elem.img}
            addon={elem.addon}
          />
        )
      } else {
        return (
          <LeftSection
            title={elem.title}
            description={elem.description}
            img={elem.img}
            addon={elem.addon}
          />
        )
      }
    })}
    <HorizontalSection type={2} title={"Sube tu negocio de nivel"} />
    <HorizontalMessage />
  </Layout>
)

export default Restaurantes
