import React from "react"
import styled from "styled-components"
import MainButton from "../MainButton"

const Hero = props => (
  <Container>
    <HeroGroup>
      <Title>
        <h1>
          Haz crecer tu <br />
          negocio con nosotros
        </h1>
        <p>
          {/* Cravy te trae nuevos clientes a tu restaurante y te ayuda a fidelizar
          a los que ya te conocen a través del pedido móvil. */}
          Choosify consigue atraer nuevos clientes a tu negocio y te ayuda a fidelizarlos y alinearlos con tu marca.
        </p>
      </Title>
      <a
        href="mailto:hola@choosify.es?subject=Estoy%20interesado%20en%20obtener%20más%20información"
        class="open-intercom"
        target="_blank"
        rel="nofollow"
      >
        <MainButton title={"ESTOY INTERESADO"} />
      </a>
    </HeroGroup>
    <Image />
  </Container>
)

export default Hero

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 600px;

  @media (max-width: 776px) {
    flex-direction: column;
    justify-content: flex-end;
  }
`

const HeroGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  left: 10%;
  justify-content: center;
  position: relative;
  z-index: 2;

  @media (max-width: 776px) {
    width: 100%;
    align-items: center;
    padding: 0px;
    padding-bottom: 20px;
    left: 0%;
    z-index: 0;
  }
`
const Image = styled.div`
  width: 75%;
  background: url(${require("../../images/landing/partners-main.jpeg")});
  background-size: cover;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    background-color: white;
    position: absolute;
    z-index: 1;
    height: 601px;
    left: -20%;
    width: 40%;
    transform: skew(17deg);
  }

  @media (max-width: 776px) {
    width: 100%;
    height: 300px;

    &::after {
      display: none;
    }
  }
`

const Title = styled.div`
  max-width: 400px;
  margin-bottom: 42px;

  @media (max-width: 776px) {
    margin-bottom: 0px;
    text-align: center;
    padding: 30px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`
