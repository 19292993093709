import React, { Fragment } from "react"
import styled from "styled-components"

const Span = styled.span`
  margin-bottom: 20px;
  display: block;
`
const Span2 = styled.span`
  display: block;
`
const Perk = styled.p`
  color: #00cc9a;
  font-weight: 600;
`

export const mainHorizontalSection1 = [
  {
    title: "Más clientes",
    description:
      "Abre la puerta de tu negocio a nuevos clientes y fidelízalos con tu marca.",
    icon:
    require("../images/landing/magnet.png"),
  },
  {
    title: "Más ingresos",
    description:
      "Aumenta tus ventas y eleva el ticket medio de tus clientes.",
    icon:
    require("../images/landing/money.png"),
  },
  {
    title: "Más valor de marca",
    description:
      "Ofrece a tus clientes las últimas tendencias e innovaciones del sector.",
    icon:
    require("../images/landing/gem.png"),
  },
]

export const mainHorizontalSection2 = [
  {
    title: "Clientes valiosos",
    description:
      "Consigue clientes fieles a tu negocio qué atraigan a sus amigos y familiares.",
    icon:
    require("../images/landing/customer.png"),
  },
  {
    title: "Más visibilidad",
    description:
      "Destaca tu negocio frente a la competencia en redes sociales, buscadores y Google Maps.",
    icon:
    require("../images/landing/pin.png"),
  },
  {
    title: "Más margen",
    description:
      "Publicidad dirigida a clientes potenciales para obtener mayor beneficio a menor coste.",
    icon:
    require("../images/landing/graph.png"),
  },
]

export const sectionContent = [
  {
    title: "Redes sociales y presencia en la red",
    description: (
      <Fragment>
        <Span>
          Gran parte de tu público potencial se encuentra en Instagram y Facebook. En Choosify te ayudamos
          a llevar a otro nivel tu presencia en redes sociales y aumentar seguidores de manera real.{" "}
        </Span>{" "}
        <Span2>
          Te ayudamos a crear contenido de calidad y a utilizar las herramientas más punteras de todas estas 
          aplicaciones de manera sencilla. Además, te asesoramos para que tus sorteos y promociones sean todo un éxito.
        </Span2>
      </Fragment>
    ),
    img:
      // require("../images/landing/cravy-setup.jpeg"),
      require("../images/landing/food.jpg"),
    addon: (
      <Fragment>
        <Perk>📍 También optimizamos y mejoramos la presencia de tu negocio en Google Maps.</Perk>
      </Fragment>
    ),
  },
  {
    title: "Publicidad e influencers",
    description: (
      <Fragment>
        <Span>
          Es posible darse a conocer y atraer nuevos clientes sin gastar mucho dinero en publicidad.
          En Choosify analizamos tu negocio y definimos cual es la campaña publicitaria que más se adapta 
          a los objetivos que se pretenden conseguir.
        </Span>{" "}
        <Span2>
          Utilizamos Facebook ADS y Google ADS para crear publicidad segmentada y focalizada a los clientes que buscamos.
          Además, te ayudamos a encontrar influencers que se alineen con tu marca para que te ayuden a promocionar tu negocio o productos.
          {/* Además, con nuestras herramientas podrás hacer llegar tus promociones
          y lanzamientos de nuevos productos directamente al móvil de tus
          clientes en el mejor momento. */}
        </Span2>
      </Fragment>
    ),
    img:
    // require("../images/landing/cravy-customers-compressor.jpeg"),
    require("../images/landing/publicity-1.jpg"),

    addon: (
      <Fragment>
      <Perk>💼 Te creamos un perfil de empresa en Facebook e Instagram para utilizar sus potentes herramientas de publicidad.</Perk>
    </Fragment>
    ),
    
  },
  {
    title: "Una APP propia para tu negocio",
    description: (
      <Fragment>
        <Span>
          Imagina tener tu propia APP para Android, iOS y versión web. Ahora es posible con nosotros.
          Creamos la APP para tu negocio para que todos tus clientes puedan consultar toda la gama de 
          productos a la venta y realizar pedidos pagándolos directamente en la APP.{" "}
        </Span>{" "}
        <Span2>
          Tu negocio en el bolsillo de tus clientes 24 horas al día, 7 días a la semana. Además, dispondrás 
          de un panel de administración integrado en la APP para que tú mismo puedas actualizar los contenidos que se muestran en la APP.
        </Span2>
      </Fragment>
    ),
    img:
      // require("../images/landing/cravy-partner.jpeg"),
      require("../images/landing/boy-1.jpg"),
      addon: (
        <Fragment>
        <Perk>📢 Envía notificaciones PUSH a todos los clientes que tengan la APP descargada y consigue difusión instantánea de ofertas y novedades.</Perk>
      </Fragment>
      ),
  },
  {
    title: "Obtén los datos que te faltan",
    description: (
      <Fragment>
        <Span>
          Te enviamos informes sobre tu rendimiento, feedback de tus clientes,
          tendencias de mercado en tu zona y nuestro equipo te ayudará a aplicar
          todos esos datos para mejorar tu negocio.{" "}
        </Span>{" "}
        <Span2>
          Se realizarán 2 reuniones presenciales al mes. En la primera se estudiarán y debatirán las estrategias a seguir y los objetivos que se pretenden conseguir.
          En la segunda se analizarán los resultados obtenidos en las campañas realizadas para conseguir dichos objetivos.
        </Span2>
      </Fragment>
    ),
    img:
    // require("../images/landing/cravy-data.jpeg"),
    require("../images/landing/analytics-1.jpg"),
    addon: (
      <Fragment>
      <Perk>📞 Asistencia telefónica gratuita para cualquier consulta durante todo el horario comercial de tu negocio.</Perk>
    </Fragment>
    ),
  },
]
